<template>
  <div id="canvas">
    <div class="download-search">
      <div class="row">
        <div class="col-md-4">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Dealership Performance
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-8">
          <div class="download-links">
            <ul class="d-flex align-items-center justify-content-end">
              <!---start--->
              <li class="timeline">
                <button
                  type="button"
                  v-on:click="getReports('today')"
                  v-bind:class="filters_.duration == 'today' ? 'current' : ''"
                >
                  <span class="today">Today</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="filters_.duration == 'month' ? 'current' : ''"
                  v-on:click="getReports('month')"
                >
                  <span class="today">This Month</span>
                </button>
              </li>
              <li class="timeline datepicker-timeline">
                <button
                  type="button"
                  class="calender"
                  v-bind:class="filters_.duration == 'custom' ? 'current' : ''"
                >
                  <date-range-picker
                    :opens="'left'"
                    :ranges="false"
                    :locale-data="{ format: 'yyyy-mm-dd' }"
                    v-model="filters_.customDate"
                    @update="getReports('custom')"
                  ></date-range-picker
                  ><span class="calender">Custom Date</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  @click="exportSalesRapData(filters_.duration)"
                  :disabled="btnLoader"
                  type="button"
                >
                  <span v-if="btnLoader"
                    ><span class="spinner-grow spinner-grow-sm"></span>
                    Loading...</span
                  >
                  <span v-if="!btnLoader"> Export CSV</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- ounter-grid -->
    <div class="">
      <div class="dataTables_wrapper no-footer">
        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Used or New Vehicles Report</h4>
            </div>
          </div>

          <table
            v-show="loader"
            id="dealerShipPerformanceReportForNewAndUsedVehicles"
            class="customReportsTable display"
          >
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-center">Total Leadsheets</th>
                <th class="text-center">Total Contacted</th>
                <th class="text-center">Total Scheduled</th>
                <th class="text-center">Total Visits</th>
                <th class="text-center">Total Demos</th>
                <th class="text-center">Total Demo Percentage</th>
                <th class="text-center">Total Desked</th>
                <th class="text-center">Desked %</th>

                <th class="text-center">Total Sold</th>
                <th class="text-center">Total Lost</th>
                <th class="text-center">Leadsheet Sold %</th>
                <th class="text-center">Appts Set</th>
                <th class="text-center">Set %</th>
                <th class="text-center">Appt Shown</th>
                <th class="text-center">Shown %</th>
                <th class="text-center">To</th>
                <th class="text-center">To %</th>

                <th class="text-center">Appt Sold</th>
                <th class="text-center">Appt Set to Closing %</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="data in dealerShipPerformanceReportForNewAndUsedVehicles"
                :key="data.key"
              >
                <td class="text-center">{{ data.type }}</td>
                <td class="text-center">{{ data.total_leads }}</td>
                <td class="text-center">{{ data.total_contacted }}</td>
                <td class="text-center">{{ data.total_scheduled }}</td>
                <td class="text-center">{{ data.total_visits }}</td>
                <td class="text-center">{{ data.total_demos }}</td>
                <td class="text-center">{{ data.total_demos_percentage  }} % </td>
                <td class="text-center">{{ data.total_desked }}</td>
                <td class="text-center">{{ data.desked_percentage  }} %</td>

                <td class="text-center">{{ data.total_sold }}</td>
                <td class="text-center">{{ data.total_lost }}</td>
                <td class="text-center">{{ data.percentage_sold  }} </td>
                <td class="text-center">{{ data.appointments_set }}</td>
                <td class="text-center">{{ data.set_percentage   }} %</td>
                <td class="text-center">{{ data.appointments_show }}</td>

                <td class="text-center">
                  {{ data.appointmentshow_percentage  }} %
                </td>
                <td class="text-center">{{ data.to }}</td>
                <td class="text-center">{{ data.to_percentage  }} %</td>
                <td class="text-center">{{ data.appointments_sold  }}</td>
                <td class="text-center">
                  {{ data.percentage_appointments_sold  }} %
                </td>
              </tr>
            </tbody>
          </table>
          <div style="width: 200px; margin: 0px auto">
            <!-- <atom-spinner
              v-if="!loader"
              :animation-duration="1000"
              :size="60"
              :color="'#3151A1'"
            /> -->
            <img v-if="!loader" style="display: block;margin-left: auto;margin-right: auto;width: 100px;" src="../../assets/img/Spinner.gif" />
          </div>
        </div>
      </div>
    </div>
    <li class="timeline" style="float: right; list-style-type: none">
      <button
        @click="exportdealershipperformance(filters_.duration)"
        :disabled="leadreportloader"
        type="button"
      >
        <span v-if="leadreportloader"
          ><span class="spinner-grow spinner-grow-sm"></span> Loading...</span
        >
        <span v-if="!leadreportloader"> Export CSV</span>
      </button>
    </li>
    <div class="">
      <div class="dataTables_wrapper no-footer">
        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Leads Report</h4>
            </div>
          </div>
          <table
            id="dealerShipPerformanceReportTotalLeadSheets"
            class="customReportsTable display"
          >
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-center">Total Leadsheets</th>
                <th class="text-center">Total Contacted</th>
                <th class="text-center">Total Scheduled</th>
                <th class="text-center">Total Visits</th>
                <th class="text-center">Total Demos</th>
                <th class="text-center">Demo %</th>
                <th class="text-center">Total Desked</th>
                <th class="text-center">Desked %</th>
                <th class="text-center">Total Sold</th>
                <th class="text-center">Sold %</th>
                <th class="text-center">Total Lost</th>
                <th class="text-center">Leadsheet Sold %</th>
                <th class="text-center">Appts Set</th>
                <th class="text-center">Set %</th>
                <th class="text-center">Appt Shown</th>
                <th class="text-center">Show %</th>
                <th class="text-center">To</th>
                <th class="text-center">To %</th>
                <th class="text-center">Appt Sold</th>
                <th class="text-center">Appt Set to Closing %</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="data in dealerShipPerformanceReportTotalLeadSheets"
                :key="data.key"
              >
                <td class="text-center">{{ data.type }}</td>
                <td class="text-center">{{ data.total_leads }}</td>
                <td class="text-center">{{ data.total_contacted }}</td>
                <td class="text-center">{{ data.total_scheduled }}</td>
                <td class="text-center">{{ data.total_visits }}</td>
                <td class="text-center">{{ data.total_demos }}</td>
                <td class="text-center">{{ data.total_demos_percentage }} %</td>

                <td class="text-center">{{ data.total_desked }}</td>
                <td class="text-center">{{ data.desked_percentage }} %</td>
                <td class="text-center">{{ data.total_sold }}</td>
                <td class="text-center">{{ data.total_sold_percentage }} %</td>

                <td class="text-center">{{ data.total_lost }}</td>
                <td class="text-center">{{ data.percentage_sold }}</td>
                <td class="text-center">{{ data.appointments_set }}</td>
                <td class="text-center">{{ data.set_percentage }} %</td>
                <td class="text-center">{{ data.appointments_show }}</td>
                <td class="text-center">
                  {{ data.appointments_show_percentage }} %
                </td>
                <td class="text-center">{{ data.to }}</td>
                <td class="text-center">{{ data.to_percentage   }} % </td>
                <td class="text-center">{{ data.appointments_sold }}</td>
                <td class="text-center">
                  {{ data.percentage_appointments_sold }} %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- graph-area -->
</template>
<script>
import axios from "axios";
// import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import axios from "axios";
// import moment from "moment";
// import jsPDF from "jspdf";
// import * as htmlToImage from "html-to-image";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import moment from "moment";
// import { AtomSpinner } from "epic-spinners";
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";
export default {
  name: "Dealership",
  components: { DateRangePicker },
  data() {
    return {
      btnLoader: false,
      leadreportloader: false,
      loader: false,
      dealerShipPerformanceReportForNewAndUsedVehicles: null,
      dealerShipPerformanceReportForNewAndUsedVehiclesObject: null,
      dealerShipPerformanceReportTotalLeadSheets: null,
      dealerShipPerformanceReportTotalLeadSheetsObject: null,
      filters_: {
        duration: "month",
        customDate: {},
        id: 0,
        tm_id: 0,
        from: "",
        to: "",
        export: false,
      },
    };
  },
  mounted() {
    this.getReports();
  },
  beforeUpdate() {
    if (this.dealerShipPerformanceReportForNewAndUsedVehiclesObject) {
      this.dealerShipPerformanceReportForNewAndUsedVehiclesObject.fnDestroy();
    }
    if (this.dealerShipPerformanceReportTotalLeadSheetsObject) {
      this.dealerShipPerformanceReportTotalLeadSheetsObject.fnDestroy();
    }
  },
  methods: {
    exportSalesRapData(duration) {
      this.btnLoader = true;
      this.filters_.export = true;
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(axios.defaults.baseURL + "dealershipperfromance", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.filters_.export = false;
          this.btnLoader = false;

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "dealership_performancereport.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    exportdealershipperformance(duration) {
      this.filters_.export = true;
      this.leadreportloader = true;
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(
          axios.defaults.baseURL + "dealershipperfromance/total",
          this.filters_,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          this.filters_.export = false;
          this.leadreportloader = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "dealership_performancereport.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    getReports(duration = "month") {
      this.getDealershipPerformanceReport(duration);
      this.getDealershipPerformanceTotalLeadsReport(duration);
    },
    getDealershipPerformanceReport(duration) {
      this.loader = false;
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(axios.defaults.baseURL + "dealershipperfromance", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((response) => {
          this.loader = true;
          if (
            $.fn.DataTable.isDataTable(
              "#dealerShipPerformanceReportForNewAndUsedVehicles"
            )
          ) {
            $("#dealerShipPerformanceReportForNewAndUsedVehicles")
              .DataTable()
              .destroy();
          }

          this.dealerShipPerformanceReportForNewAndUsedVehicles = response.data;
          setTimeout(() => {
            if (
              $.fn.DataTable.isDataTable(
                "#dealerShipPerformanceReportForNewAndUsedVehicles"
              )
            ) {
              $("#dealerShipPerformanceReportForNewAndUsedVehicles")
                .DataTable()
                .draw();
            } else {
              $("#dealerShipPerformanceReportForNewAndUsedVehicles").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getDealershipPerformanceTotalLeadsReport(duration) {
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(
          axios.defaults.baseURL + "dealershipperfromance/total",
          this.filters_,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if (
            $.fn.DataTable.isDataTable(
              "#dealerShipPerformanceReportTotalLeadSheets"
            )
          ) {
            $("#dealerShipPerformanceReportTotalLeadSheets")
              .DataTable()
              .destroy();
          }
          this.dealerShipPerformanceReportTotalLeadSheets = response.data;
          setTimeout(() => {
            if (
              $.fn.DataTable.isDataTable(
                "#dealerShipPerformanceReportTotalLeadSheets"
              )
            ) {
              $("#dealerShipPerformanceReportTotalLeadSheets")
                .DataTable()
                .draw();
            } else {
              $("#dealerShipPerformanceReportTotalLeadSheets").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
  },
};
</script>
<style scoped>
.timeline {
  margin: 0px !important;
}
.datepicker-timeline {
  overflow: visible;
  margin-right: 5px !important;
}
.calender {
  position: relative;
}
.calender .vue-daterange-picker {
  position: absolute;
}
.calender .daterangepicker.opensleft {
  right: -100px;
}
.td {
  position: relative;
  right: -7px;
}
@import "../../assets/css/dataTables.css";
</style>
