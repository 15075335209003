import { render, staticRenderFns } from "./DealerShipPerformance.vue?vue&type=template&id=90ff8328&scoped=true&"
import script from "./DealerShipPerformance.vue?vue&type=script&lang=js&"
export * from "./DealerShipPerformance.vue?vue&type=script&lang=js&"
import style0 from "./DealerShipPerformance.vue?vue&type=style&index=0&id=90ff8328&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90ff8328",
  null
  
)

export default component.exports