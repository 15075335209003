<template>
  <div id="canvas">
    <div class="download-search">
      <div class="row">
        <div class="col-md-9">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Back into Report
              </li>
            </ol>
          </nav>
        </div>
        <!-- <div class="col-md-6">
          <div class="perfor-search lead-search sourceby">
            <form action="">
              <div class="form-row d-flex justify-content-end">
                <div class="form-group col-md-8">
                  <select
                    class="form-control"
                    v-model="search"
                    v-on:change="getBackIntoReportData"
                  >
                    <option value="">Search by Agent or Team</option>
                    <option
                      v-for="agent in agentsOrTeam"
                      :key="agent.id"
                      :value="agent.id"
                    >
                      {{ agent.text }}
                    </option>
                  </select>
                    <SingleSelect
                v-on:change="getUsersReport('', 'activity', 'year')"
                :searchable="true"
                id="a_usersadss"
                textColumn="text"
                placeholder="Select Agent"
                v-model="selectedagent"
                :options="agentsOrTeam"
              ></SingleSelect>
                </div>
              </div>
            </form>
          </div>
        </div> -->
        <div class="col-md-3">
          <div class="lead-search sourceby">
            <div class="form-group">
              <label>Agent</label>
              <SingleSelect
                v-on:change="onChnagebackreport"
                :searchable="true"
                id="a_usersadss"
                textColumn="text"
                placeholder="Select Agent"
                :alreadySelected="selectedagent"
                v-model="selectedagent"
                :options="agentsOrTeam"
              ></SingleSelect>

              <!-- <select class="form-control" id="by" v-model="filterSearch.by">
                        <option value="">Select Value</option>
                        <option v-for="source in LSources" :value="source.ls_id" :key="source.ls_id" >{{source.ls_name}}</option>
                    </select> -->
            </div>
            <li class="timeline" style="float: right; list-style-type: none">
                <button
                  @click="exportSalesRapData(filters_.duration)"
                  :disabled="btnLoader"
                  type="button"
                >
                  <span v-if="btnLoader"
                    ><span class="spinner-grow spinner-grow-sm"></span>
                    Loading...</span
                  >
                  <span v-if="!btnLoader"> Export CSV</span>
                </button>
            </li>
          </div>

          <!-- <div  class="lead-search sourceby" >
                <div style="position: relative;bottom: 50px;">
                    <span>By</span>
                    <multi-select :searchable="true"  id="a_users" textColumn="text" placeholder="Select sources" v-model="filterSearch.by" :options="LSources" ></multi-select>
                </div>
            </div> -->
        </div>
      </div>
    </div>
    <!-- ounter-grid -->
    <div class="">
      <div class="dataTables_wrapper no-footer">
        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Back into Report</h4>
            </div>
          </div>
          <div class="dataTables_wrapper no-footer">
            <table
              id="backIntoReportData"
              class="customReportsTable display dataTable no-footer"
            >
              <thead>
                <tr>
                  <th class="text-center"></th>
                  <th class="text-center">Historic</th>
                  <th class="text-center">Goal</th>
                  <th class="text-center">MTD</th>
                  <th class="text-center">% of Completion</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in backIntoReportData" :key="data.key">
                  <td class="text-center">{{ data.name }}</td>
                  <td class="text-center">{{ data.historic }}</td>
                  <td class="text-center">{{ data.goals }}</td>
                  <td class="text-center">{{ data.mtd }}</td>
                  <td class="text-center">{{ data.percentage }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- graph-area -->
</template>
<script>
import axios from "axios";
import SingleSelect from "../helpers/singleSelect";
// import VueApexCharts from "vue-apexcharts";
// import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import axios from "axios";
// import moment from "moment";
// import jsPDF from "jspdf";
// import * as htmlToImage from "html-to-image";
import $ from "jquery";
// // import moment from "moment";
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";
export default {
  name: "BackIntoReport",
  components: { SingleSelect },
  data() {
    return {
      btnLoader:false,
      selectedagent: {
        id: "",
        text: "Select Agent",
      },
      search: "",
      backIntoReportData: null,
      backIntoReportDataObject: null,
      agentsOrTeam: [],
      filters_: {
        duration: "year",
        customDate: {},
        id: 0,
        tm_id: 0,
        from: "",
        to: "",
        export: false,
      },
    };
  },
  mounted() {
    this.getAgentsOrTeam();
    this.getBackIntoReportData();
  },
  beforeUpdate() {
    if (this.backIntoReportDataObject) {
      this.backIntoReportDataObject.fnDestroy();
    }
  },
  methods: {
    exportSalesRapData() {
      this.filters_.export = true;
      this.btnLoader = true;
      axios
        .post(axios.defaults.baseURL + "backintoreport", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.filters_.export = false;
          this.btnLoader = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "backintoreport.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    onChnagebackreport() {
      this.filters_.id = this.selectedagent.id;
      this.getBackIntoReportData();
    },
    getBackIntoReportData() {
      // console.log(agent_id)
      axios
        .post(axios.defaults.baseURL + "backintoreport", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#backIntoReportData")) {
            $("#backIntoReportData").DataTable().destroy();
          }
          this.backIntoReportData = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#backIntoReportData")) {
              $("#backIntoReportData").DataTable().draw();
            } else {
              $("#backIntoReportData").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getAgentsOrTeam() {
      axios
        .get("search_agent_or_team", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.data) {
            if (res.data.data.length > 0) {
              var dd = [{
                id: "",
                text: "All",
              }];
              $.each(res.data.data, function (key, value) {
                dd.push({
                  id: value.id,
                  text: value.text,
                });
              });
              this.agentsOrTeam = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.timeline {
  margin: 0px !important;
}
.datepicker-timeline {
  overflow: visible;
  margin-right: 5px !important;
}
.calender {
  position: relative;
}
.calender .vue-daterange-picker {
  position: absolute;
}
.calender .daterangepicker.opensleft {
  right: -100px;
}
.td {
  position: relative;
  right: -7px;
}
</style>
