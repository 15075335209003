<template>
  <div>
    <!-- download-links -->
    <div class="download-search">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Agent Performance Report
              </li>
            </ol>
          </nav>
        </div>
        <!-- <div class="col-md-4">
          <div class="perfor-search">
            <form action="">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <select
                    class="form-control"
                    v-model="search"
                    v-on:change="getReportBySelectedUser"
                  >
                    <option value="">Search by Agent or Team</option>
                    <option
                      v-for="agent in agentsOrTeam"
                      :key="agent.id"
                      :value="agent.id"
                    >
                      {{ agent.text }}
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div> -->
        <div class="col-md-4">
          <div class="lead-search sourceby">
            <div class="form-group">
              <label>Agents</label>
              <!-- <select
                class="form-control"
                v-model="filterSearch.agent"
                v-on:change="filterData"
              >
                <option value="">Select Agent</option>
                <option
                  v-for="emp in allEmployees"
                  :key="emp.id"
                  :value="emp.id"
                >
                  {{ emp.text }}
                </option>
              </select> -->
              <!-- <SingleSelect
                v-on:change="getReportBySelectedUser"
                :searchable="true"
                id="a_usersadss"
                textColumn="text"
                placeholder="Select Agent"
                v-model="agent"
                :options="agentsOrTeam"
              ></SingleSelect> -->
              <!-- <multi-select
                v-on:change="getReportBySelectedUser"
                :searchable="true"
                id="a_usersads"
                textColumn="text"
                placeholder="Select Agents"
                v-model="agent"
                :options="agentsOrTeam"
              ></multi-select> -->
              <multi-select
                v-on:change="getReportBySelectedUser"
                :searchable="true"
                id="report"
                textColumn="text"
                :alreadySelected="agent"
                :options="agentsOrTeam"
                v-model="agent"
                placeholder="Select Agents"
              ></multi-select>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="download-links">
            <ul class="align-items-center justify-content-end">
              <!---start--->
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="
                    agentActivity_.duration == 'today' ? 'current' : ''
                  "
                  v-on:click="getReport('', 'activity', 'today')"
                >
                  <span class="today">Today</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="
                    agentActivity_.duration == 'year' ? 'current' : ''
                  "
                  v-on:click="getReport('', 'activity', 'year')"
                >
                  <span class="today">This Year</span>
                </button>
              </li>
              <li class="timeline datepicker-timeline">
                <button
                  type="button"
                  class="calender"
                  v-bind:class="
                    agentActivity_.duration == 'custom' ? 'current' : ''
                  "
                >
                  <date-range-picker
                    :opens="'left'"
                    :ranges="false"
                    :locale-data="{ format: 'yyyy-mm-dd' }"
                    v-model="agentActivity_.customDate"
                    @update="getReport('', 'activity', 'custom')"
                  ></date-range-picker
                  ><span class="calender">Custom Date</span>
                </button>
              </li>
              <!-- end -->
              <li>
                <a href="#" v-on:click="downloadIt('print')"
                  ><img
                    class="img-fluid print-icon"
                    src="../../assets/img/print.svg"
                    alt=""
                /></a>
              </li>
              <li>
                <a href="#" v-on:click="downloadIt('img')"
                  ><img class="img-fluid" src="../../assets/img/pdf.svg" alt=""
                /></a>
              </li>
              <li>
                <a href="#" v-on:click="downloadIt('pdf')"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/pdfp.svg"
                    alt=""
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- download-links -->
    <div id="download_area">
      <!-- graph-area -->
      <div class="graph-area">
        <div class="grap-title">
          <div class="title-grap">
            <h4>Overall activities</h4>
          </div>
        </div>

        <div class="graph-box">
          <div class="graph">
            <div class="ct-chart">
              <apexcharts
                height="280"
                type="line"
                :options="chartOptions"
                :series="leadsChartData"
                v-if="leadsChartData"
              ></apexcharts>
            </div>
          </div>
          <div class="Sold-Leads">
            <ul>
              <li><span class="sold-dot"></span>Phone Ups</li>
              <li><span class="Leads-dot"></span>Walk Ins</li>
              <li><span class="Leased-dot"></span>Internet Leads</li>
              <li><span class="made-dot"></span>DMS</li>
              <li><span class="email-dot"></span>Sold</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- graph-area -->

      <!-- analytics-area -->
      <div class="graph-area">
        <div class="grap-title">
          <div class="title-grap">
            <h4>Performance Analysis</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="analytics-graph">
              <div class="graph">
                <apexcharts
                  type="donut"
                  height="280"
                  :options="DonutchartOptions"
                  :series="analyticsChartData.data"
                ></apexcharts>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="all-analytic">
              <div class="analytics-box row">
                <div
                  class="media col-md-6"
                  v-for="(aData, index) in analyticsData"
                  :key="index"
                >
                  <p class="mr-3"><span :class="aData.class"></span></p>
                  <div class="media-body">
                    <h5 class="mt-0">{{ aData.title }}</h5>
                    <h3>{{ aData.count }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- analytics-area -->

      <div id="leadSources">
        <!-- leads-table-area  -->
        <div class="">
          <div class="dataTables_wrapper no-footer">
            <div class="graph-area">
              <div class="d-flex justify-content-between">
                <div class="grap-title">
                  <div class="title-grap">
                    <h4>Lead Sources</h4>
                  </div>
                </div>
              </div>
              <div class="dataTables_wrapper no-footer">
                <table
                  id="leadSourcesReportDataTable"
                  class="customReportsTable display dataTable no-footer"
                >
                  <thead>
                    <tr>
                      <th class="text-center"></th>
                      <th class="text-center">#</th>
                      <th class="text-center">Sold By Source</th>
                      <th class="text-center">Closing Percentage</th>
                      <th class="text-center">
                        Store Average Closing % (Dealer Overall Agency Average)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="leadData in orderedUsers" :key="leadData">
                      <td class="text-center">{{ leadData.name }}</td>
                      <td class="text-center">{{ leadData.total_leads }}</td>
                      <td class="text-center">
                        {{ leadData.total_leads_sold }}
                      </td>
                      <td class="text-center">
                        {{ leadData.agent_closing_percentage }}
                      </td>
                      <td class="text-center">
                        {{ leadData.agency_closing_percentage }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="leadSources">
        <!-- leads-table-area  -->
        <div class="">
          <div class="dataTables_wrapper no-footer">
            <div class="graph-area">
              <div class="grap-title">
                <div class="title-grap">
                  <h4>Response Time</h4>
                </div>
              </div>
              <div class="dataTables_wrapper no-footer">
                <table
                  id="leadResponseTimeTableData"
                  class="customReportsTable display dataTable no-footer"
                >
                  <thead>
                    <tr>
                      <!-- <th class="text-center"></th> -->
                      <th class="text-center">Name</th>
                      <th class="text-center">Response Time</th>
                      <th class="text-center">Store Average Response Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in leadResponseTimeTableData"
                      :key="index"
                    >
                      <!-- <td class="text-center">Response Time</td> -->
                       <td class="text-center">
                        {{ data.name }}
                      </td>
                      <td class="text-center">
                        {{ data.touch_time }}
                      </td>
                      <td class="text-center">
                        {{ data.touchtimedelaer }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="leadSources">
        <!-- leads-table-area  -->
        <div class="">
          <div class="dataTables_wrapper no-footer">
            <div class="graph-area">
              <div class="grap-title">
                <div class="title-grap">
                  <h4>Activity</h4>
                </div>
              </div>
              <div class="dataTables_wrapper no-footer">
                <table
                  id="activityReportData"
                  class="customReportsTable display dataTable no-footer"
                >
                  <thead>
                    <tr>
                      <th class="text-center"></th>
                      <th class="text-center">Touch</th>
                      <th class="text-center">Engagement</th>
                      <th class="text-center">Engagement %</th>
                      <th class="text-center">Store Average Engagement %</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="activity in activityReportData"
                      :key="activity.id"
                    >
                      <td class="text-center">{{ activity.name }}</td>
                      <td class="text-center">{{ activity.total }}</td>
                      <td class="text-center">{{ activity.engagement }}</td>
                      <td class="text-center">
                        {{ activity.engagement_percentage }}
                      </td>
                      <td class="text-center">
                        {{ activity.engagement_percentage_agency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="print_area" class="print_area">
      <img :src="print_src" style="max-width: 100%" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
// Data Tables
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import PieChart from "../charts/PieChart.vue"
// import LineChart from "../charts/LineChart.vue"
import * as htmlToImage from "html-to-image";
//import { toJpeg } from 'html-to-image'
import { orderBy } from "lodash";
import jsPDF from "jspdf";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import SingleSelect from "../helpers/singleSelect";
import MultiSelect from "../helpers/MultiSelectWithCheckbox";
export default {
  name: "AgentReports",
  components: { DateRangePicker, apexcharts: VueApexCharts, MultiSelect },
  data() {
    return {
      search: "",
      agent: [],
      agentsOrTeam: [],
      agentActivity_: {
        duration: "year",
        customDate: {},
        id: 0,
        tm_id: 0,
        from: "",
        to: "",
      },
      agentAnalytics_: {
        duration: "year",
        customDate: {},
        id: 0,
        tm_id: 0,
        from: "",
        to: "",
      },
      activityChartData: [],
      analyticsChartData: [],
      analyticsData: [],
      base_url: axios.defaults.baseURL,
      print_src: "",
      chart_titles: [],
      leadsChartData: [],
      leadSourcesReportDataTable: [],
      leadSourcesReportDataTableObject: null,
      leadResponseTimeTableData: [],
      leadResponseTimeTableDataObject: null,
      activityReportData: [],
      activityReportDataObject: null,
      series: [44, 55, 41, 17, 15],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          width: [2, 2, 2],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        colors: ["#3151A1", "#19CD9D", "#FEAB6F", "#7795FE", "#BF6FFE"],
        xaxis: {
          categories: [],
        },
        labels: [],
        tooltip: {
          shared: true,
        },
      },
      DonutchartOptions: {
        chart: {
          type: "donut",
        },
        legend: {
          show: false,
        },
        labels: [],
        colors: [
          "#3151A1",
          "#868182",
          "#19CD9D",
          "#F95353",
          "#FEAB6F",
          "#D300FF",
          "#F5AAAA",
        ],
        dataLabels: {
          enabled: false,
        },
      },
    };
  },
  mounted() {
    let vm = this;
    this.$root.$on("edit_checkallpermisssions", function () {
      vm.agent = vm.agentsOrTeam;
      vm.getReportBySelectedUser();
    });

    this.$root.$on("edit_unselectpermisssions", function () {
      vm.agent = [];
      vm.getReportBySelectedUser();
    });
    // $("#leadSourcesTable")
    // 	.addClass("nowrap")
    // 	.dataTable();

    // this.getLeadSourcesData();
    // this.getLeadReportTimeData();
    // this.getActivityReportData();
    this.getAgentsOrTeam();
  },
  created() {
    this.getReportBySelectedUser()
    if (
      this.$route.query.agent != undefined &&
      this.$route.query.agent != "" &&
      this.$route.query.agent != null &&
      this.$route.query.agent != "undefined"
    ) {
      this.agentActivity_.id = [{ id:this.$route.query.agent }];
      this.agentActivity_.tm_id = 0;
      this.agentAnalytics_.id = [{ id:this.$route.query.agent }];
      this.agentAnalytics_.tm_id = 0;
    }
  },
  beforeUpdate() {
    this.leadSourcesReportDataTableObject.destroy();
    this.leadResponseTimeTableDataObject.destroy();
    this.activityReportDataObject.destroy();
  },
  updated() {
    // this.leadSourcesReportDataTableObject.dataTable();
  },
  methods: {
    formattedAutocompleteDisplay(result) {
      return result.text;
    },
    getReport(result = "", type = "", duration = "year") {
      console.log(type);
      this.agentActivity_.duration = duration;
      this.agentAnalytics_.duration = duration;
      if (result.selectedObject) {
        if (
          result.selectedObject.user != undefined &&
          result.selectedObject.user != "undefined" &&
          result.selectedObject.user != null &&
          result.selectedObject.user != ""
        ) {
          this.agentActivity_.id = result.selectedObject.id;
          this.agentActivity_.tm_id = 0;
        }
        if (
          result.selectedObject.team != undefined &&
          result.selectedObject.team != "undefined" &&
          result.selectedObject.team != null &&
          result.selectedObject.team != ""
        ) {
          this.agentActivity_.id = 0;
          this.agentActivity_.tm_id = result.selectedObject.id;
        }
      }
      if (this.$route.query.agent) {
        this.agentActivity_.id = [{ id:this.$route.query.agent }];
      }
      let startDate = this.agentActivity_.customDate.startDate;
      let endDate = this.agentActivity_.customDate.endDate;
      if (
        startDate != undefined &&
        startDate != "undefined" &&
        startDate != null &&
        startDate != ""
      ) {
        this.agentActivity_.from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
      }
      if (
        endDate != undefined &&
        endDate != "undefined" &&
        endDate != null &&
        endDate != ""
      ) {
        this.agentActivity_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      }
      console.log("performance report");
      this.getPerformanceReport();

      if (result.selectedObject) {
        if (
          result.selectedObject.user != undefined &&
          result.selectedObject.user != "undefined" &&
          result.selectedObject.user != null &&
          result.selectedObject.user != ""
        ) {
          this.agentAnalytics_.id = result.selectedObject.id;
          this.agentAnalytics_.tm_id = 0;
        }
        if (
          result.selectedObject.team != undefined &&
          result.selectedObject.team != "undefined" &&
          result.selectedObject.team != null &&
          result.selectedObject.team != ""
        ) {
          this.agentAnalytics_.id = 0;
          this.agentAnalytics_.tm_id = result.selectedObject.id;
        }
      }

      if (
        startDate != undefined &&
        startDate != "undefined" &&
        startDate != null &&
        startDate != ""
      ) {
        this.agentAnalytics_.from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
      }
      if (
        endDate != undefined &&
        endDate != "undefined" &&
        endDate != null &&
        endDate != ""
      ) {
        this.agentAnalytics_.to = moment(new Date(endDate)).format(
          "YYYY-MM-DD"
        );
      }
      console.log("agent activity report");
      this.getActivityReportData();
      this.agentAnalyticsReport();
      this.getLeadSourcesData();
      this.getLeadReportTimeData();
    },
    getPerformanceReport() {
      //console.log(this.agentActivity_)
      axios
        .post("agent_performance", this.agentActivity_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res.data)
          if (res.data.success) {
            // this.activityChartData = res.data.success
            this.chartOptions = {
              labels: res.data.success.labels,
            };

            this.leadsChartData = res.data.success.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    agentAnalyticsReport() {
      console.log(this.agentAnalytics_);
      axios
        .post("agent_performance_analytics", this.agentAnalytics_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.analyticsData = res.data.success;
            this.analyticsChartData = res.data.chart_data;
            this.DonutchartOptions = {
              labels: res.data.chart_data.labels,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadIt(type = "") {
      if (type == "img") {
        htmlToImage
          .toJpeg(document.getElementById("download_area"))
          .then(function (dataUrl) {
            var link = document.createElement("a");
            link.download = Date.now() + ".jpeg";
            link.href = dataUrl;
            link.click();
          });
      } else if (type == "pdf") {
        const doc = new jsPDF("p", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        htmlToImage
          .toJpeg(document.getElementById("download_area"))
          .then(function (dataUrl) {
            doc.addImage(dataUrl, "JPEG", 0, 0, width, height);
            doc.save();
          });
      } else if (type == "print") {
        let vm = this;
        htmlToImage
          .toJpeg(document.getElementById("download_area"))
          .then(function (dataUrl) {
            //console.log(dataUrl)
            vm.print_src = dataUrl;
            setTimeout(() => {
              vm.$htmlToPaper("print_area");
            }, 500);
          });
      }
    },
    getLeadSourcesData() {
      axios
        .post(
          axios.defaults.baseURL + "agent_performance/leads-sources",
          {
            customDate: this.agentActivity_.customDate,
            duration: this.agentActivity_.duration,
            from: this.agentActivity_.from,
            agent_id: this.agentActivity_.id,
            tm_id: this.agentActivity_.tm_id,
            to: this.agentActivity_.to,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#leadSourcesReportDataTable")) {
            $("#leadSourcesReportDataTable").DataTable().destroy();
          }
          this.leadSourcesReportDataTable = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#leadSourcesReportDataTable")) {
              $("#leadSourcesReportDataTable").DataTable().draw();
            } else {
              $("#leadSourcesReportDataTable").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getLeadReportTimeData() {
      axios
        .post(
          axios.defaults.baseURL + "agent_performance/leads-response-time",
          {
            customDate: this.agentActivity_.customDate,
            duration: this.agentActivity_.duration,
            from: this.agentActivity_.from,
            agent_id: this.agentActivity_.id,
            tm_id: this.agentActivity_.tm_id,
            to: this.agentActivity_.to,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#leadResponseTimeTableData")) {
            $("#leadResponseTimeTableData").DataTable().destroy();
          }
          this.leadResponseTimeTableData = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#leadResponseTimeTableData")) {
              $("#leadResponseTimeTableData").DataTable().draw();
            } else {
              $("#leadResponseTimeTableData").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getActivityReportData() {
      axios
        .post(
          axios.defaults.baseURL + "agent_performance/activity",
          {
            customDate: this.agentActivity_.customDate,
            duration: this.agentActivity_.duration,
            from: this.agentActivity_.from,
            id: this.agentActivity_.id,
            tm_id: this.agentActivity_.tm_id,
            to: this.agentActivity_.to,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#activityReportData")) {
            $("#activityReportData").DataTable().destroy();
          }
          this.activityReportData = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#activityReportData")) {
              $("#activityReportData").DataTable().draw();
            } else {
              $("#activityReportData").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
      // closing-percentage
    },
    getAgentsOrTeam() {
      // axios
      //   .get(axios.defaults.baseURL + "search_agent_or_team", {
      //     headers: {
      //       Authorization: "Bearer " + this.$storage.get("auth").token,
      //     },
      //   })
      //   .then((response) => {
      //     this.agentsOrTeam = response.data.data;
      //   });

      axios
        .get("search_agent_or_team", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.data) {
            if (res.data.data.length > 0) {
              var dd = [];
              $.each(res.data.data, function (key, value) {
                dd.push({
                  id: value.id,
                  text: value.text,
                });
              });
              this.agentsOrTeam = dd;
            
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getReportBySelectedUser() {
      console.log(this.agent);
      this.agentActivity_.id = this.agent;
      this.agentAnalytics_.id = this.agent;
      this.getReport();
    },
  },
  computed: {
    autocompleteAuthHeaders() {
      return { Authorization: "Bearer " + this.$storage.get("auth").token };
    },
    orderedUsers: function () {
      return orderBy(
        this.leadSourcesReportDataTable,
        ["agent_closing_percentage"],
        ["desc"]
      );
    },
  },
};
</script>

<style scoped>
.print_area {
  display: none;
}
.lead-search .vue-daterange-picker {
  width: 100%;
}
@import "../../assets/css/dataTables.css";
.lead-search .vue-daterange-picker {
  width: 100%;
}
.timeline {
  margin: 0px !important;
}
.datepicker-timeline {
  overflow: visible;
  margin-right: 5px !important;
}
.calender {
  position: relative;
}
.calender .vue-daterange-picker {
  position: absolute;
}
.calender .daterangepicker.opensleft {
  right: -100px;
}
.td {
  position: relative;
  right: -7px;
}
.dataTables_filter,
.dataTables_info {
  display: none;
}
.Sold-Leads li {
  margin: 0px 20px;
}
</style>
