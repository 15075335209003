<template>
  <div id="canvas">
    <div class="download-search">
      <div class="row">
        <div class="col-sm-12">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                System Report
              </li>
            </ol>
          </nav>
          <li
            style="
              list-style-type: none;
              position: relative;
              bottom: 39px;
              float: right;
            "
            class="timeline"
          >
            <button
              @click="exportSalesRapData()"
              :disabled="btnLoader"
              type="button"
            >
              <span v-if="btnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!btnLoader"> Export CSV</span>
            </button>
          </li>
        </div>
      </div>
    </div>
    <!-- ounter-grid -->
    <div class="">
      <div class="dataTables_wrapper no-footer">
        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>System Report</h4>
            </div>
          </div>
          <table id="systemReport" class="customReportsTable display">
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in systemReport" :key="data.key">
                <td>{{ data.name }}</td>
                <td>{{ data.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- graph-area -->
</template>
<script>
import axios from "axios";
// import VueApexCharts from "vue-apexcharts";
// import DateRangePicker from "vue2-daterange-picker";
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import axios from "axios";
// import moment from "moment";
// import jsPDF from "jspdf";
// import * as htmlToImage from "html-to-image";
import $ from "jquery";
// // import moment from "moment";
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";
export default {
  name: "AppointmentByRap",
  components: {},
  data() {
    return {
      btnLoader: false,
      filters_: {
        export: false,
      },
      systemReport: null,
      systemReportObject: null,
    };
  },
  mounted() {
    console.log("component mounted");
    this.getSystemReport();
  },
  beforeUpdate() {
    if (this.systemReportObject) {
      this.systemReportObject.fnDestroy();
    }
  },
  methods: {
    exportSalesRapData() {
      this.filters_.export = true;
      this.btnLoader = true;
      axios
        .post(axios.defaults.baseURL + "systemreport", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          setTimeout(() => {
            this.systemReportObject = $("#systemReport").dataTable({
              responsive: true,
              paging: false,
              bFilter: false,
              ordering: false,
              bInfo: false,
            });
          });
          this.filters_.export = false;
          this.btnLoader = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "systemreport.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    getSystemReport() {
      axios
        .post(
          axios.defaults.baseURL + "systemreport",
          {},
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          this.systemReport = response.data;
          setTimeout(() => {
            this.systemReportObject = $("#systemReport").dataTable({
              responsive: true,
              paging: false,
              bFilter: false,
              ordering: false,
              bInfo: false,
            });
          });
        });
    },
  },
};
</script>
<style scoped>
</style>
