<template>
  <div id="canvas">
    <div class="download-search">
      <div class="row">
        <div class="col-md-4">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Response Time Report
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-8">
          <div class="download-links">
            <ul class="d-flex align-items-center justify-content-end">
              <!---start--->
              <li class="timeline">
                <button
                  type="button"
                  v-on:click="getBDCReport('today')"
                  v-bind:class="filters_.duration == 'today' ? 'current' : ''"
                >
                  <span class="today">Today</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="filters_.duration == 'month' ? 'current' : ''"
                  v-on:click="getBDCReport('month')"
                >
                  <span class="today">This Month</span>
                </button>
              </li>
              <li class="timeline datepicker-timeline">
                <button
                  type="button"
                  class="calender"
                  v-bind:class="filters_.duration == 'custom' ? 'current' : ''"
                >
                  <date-range-picker
                    :opens="'left'"
                    :ranges="false"
                    :locale-data="{ format: 'yyyy-mm-dd' }"
                    v-model="filters_.customDate"
                    @update="getBDCReport('custom')"
                  ></date-range-picker
                  ><span class="calender">Custom Date</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  @click="exportSalesRapData(filters_.duration)"
                  :disabled="btnLoader"
                  type="button"
                >
                  <span v-if="btnLoader"
                    ><span class="spinner-grow spinner-grow-sm"></span>
                    Loading...</span
                  >
                  <span v-if="!btnLoader"> Export CSV</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- ounter-grid -->
    <div class="">
      <div class="dataTables_wrapper no-footer">
        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Response Time Report</h4>
            </div>
          </div>
          <table id="bdcReport"  v-show="tabledata" class="customReportsTable display">
            <thead>
              <tr>
                <th class="text-center">User</th>
                <th class="text-center">BDC Assigned Leads</th>
                <th class="text-center">Salesperson Assigned Leads</th>
                <th class="text-center">Average Response Time</th>
                <th class="text-center">
                  Average Touches Before 1st Engagement
                </th>
                <th class="text-center">Contacted</th>
                <th class="text-center">Scheduled</th>
                <th class="text-center">Visited</th>
                <th class="text-center">Sold</th>
                <th class="text-center">Lost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in orderbyresponsetime" :key="data.key">
                <td class="text-center">{{ data.agent_name }}</td>
                <td class="text-center">{{ data.bdc_assigned_leads }}</td>
                <td class="text-center">{{ data.other_assigned_leads }}</td>
                <td class="text-center">{{ data.average_response_time }}</td>
                <td class="text-center">
                  {{ data.average_touches_first_engagement }}
                </td>
                <td class="text-center">{{ data.contacted }}</td>
                <td class="text-center">{{ data.scheduled }}</td>
                <td class="text-center">{{ data.visits }}</td>
                <td class="text-center">{{ data.sold }}</td>
                <td class="text-center">{{ data.lost }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- graph-area -->
</template>
<script>
import axios from "axios";
// import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import axios from "axios";
// import moment from "moment";
// import jsPDF from "jspdf";
// import * as htmlToImage from "html-to-image";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import moment from "moment";
import { orderBy } from "lodash";
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";
export default {
  name: "AppointmentByRap",
  components: { DateRangePicker },
  data() {
    return {
      btnLoader: false,
      tabledata:true,
      bdcReport: null,
      bdcReportObject: null,
      filters_: {
        duration: "month",
        customDate: {},
        id: 0,
        tm_id: 0,
        from: "",
        to: "",
        export: false,
      },
    };
  },
  mounted() {
    console.log("component mounted");
    this.getBDCReport();
  },
  computed: {
  
    orderbyresponsetime: function () {
      return orderBy(this.bdcReport, "average_response_time", "asc");
    },
  },
  beforeUpdate() {
    if (this.bdcReportObject) {
      this.bdcReportObject.fnDestroy();
    }
  },
  methods: {
    exportSalesRapData(duration) {
      console.log(duration);
      this.filters_.export = true;
      this.btnLoader = true;
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(axios.defaults.baseURL + "responsetimereport", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.filters_.export = false;
          this.btnLoader = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "responsetimereport.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    getBDCReport(duration = "month") {
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(axios.defaults.baseURL + "responsetimereport", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#bdcReport")) {
            $("#bdcReport").DataTable().destroy();
          }
          this.tabledata = true;
          this.bdcReport = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#bdcReport")) {
              $("#bdcReport").DataTable().draw();
            } else {
              $("#bdcReport").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
  },
};
</script>
<style scoped>
.timeline {
  margin: 0px !important;
}
.datepicker-timeline {
  overflow: visible;
  margin-right: 5px !important;
}
.calender {
  position: relative;
}
.calender .vue-daterange-picker {
  position: absolute;
}
.calender .daterangepicker.opensleft {
  right: -100px;
}
.td {
  position: relative;
  right: -7px;
}
@import "../../assets/css/dataTables.css";
</style>
