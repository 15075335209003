<template>
  <div id="canvas">
    <div class="download-search">
      <div class="row">
        <div class="col-md-4">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Sold Recap Report
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-8">
          <div class="download-links">
            <ul class="d-flex align-items-center justify-content-end">
              <!---start--->
              <li class="timeline">
                <button
                  type="button"
                  v-on:click="getReports('today')"
                  v-bind:class="filters_.duration == 'today' ? 'current' : ''"
                >
                  <span class="today">Today</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="filters_.duration == 'month' ? 'current' : ''"
                  v-on:click="getReports('month')"
                >
                  <span class="today">This Month</span>
                </button>
              </li>
              <li class="timeline datepicker-timeline">
                <button
                  type="button"
                  class="calender"
                  v-bind:class="filters_.duration == 'custom' ? 'current' : ''"
                >
                  <date-range-picker
                    :opens="'left'"
                    :ranges="false"
                    :locale-data="{ format: 'yyyy-mm-dd' }"
                    v-model="filters_.customDate"
                    @update="getReports('custom')"
                  ></date-range-picker
                  ><span class="calender">Custom Date</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  @click="exportSalesRapData(filters_.duration)"
                  :disabled="btnLoader"
                  type="button"
                >
                  <span v-if="btnLoader"
                    ><span class="spinner-grow spinner-grow-sm"></span>
                    Loading...</span
                  >
                  <span v-if="!btnLoader"> Export CSV</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- ounter-grid -->
    <div class="">
      <div class="dataTables_wrapper no-footer">
        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Sold Recap Report</h4>
            </div>
          </div>
          <table id="soldRecapReport" class="customReportsTable display">
            <thead>
              <tr>
                <th class="text-center">Sold</th>
                <th class="text-center">New</th>
                <th class="text-center">Used</th>
                <th class="text-center">Average Days to Sold</th>
                <th class="text-center">Total Gross</th>
                <th class="text-center">Average Gross</th>
                <th class="text-center">Average Gross New</th>
                <th class="text-center">Average Gross Used</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in soldRecapReport" :key="data.key">
                <td class="text-center">{{ data.sold }}</td>
                <td class="text-center">{{ data.new }}</td>
                <td class="text-center">{{ data.used }}</td>
                <td class="text-center">{{ data.average_days_to_sold }}</td>
                <td class="text-center">{{ data.total_gross }}</td>
                <td class="text-center">{{ data.average_gross }}</td>
                <td class="text-center">{{ data.average_gross_new }}</td>
                <td class="text-center">{{ data.average_gross_used }}</td>
              </tr>
            </tbody>
          </table>
          <img v-if="soldRecapReportLoader" style="display: block;margin-left: auto;margin-right: auto;width: 100px;" src="../../assets/img/Spinner.gif" />
        </div>
      </div>
    </div>
    <div class="">
      <div class="dataTables_wrapper no-footer">
        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Desking Recap Report</h4>
            </div>
          </div>
          <table id="soldRecapReportYearly" class="customReportsTable display">
            <thead>
              <tr>
                <th class="text-center">Period</th>
                <th class="text-center">Sold</th>
                <th class="text-center">New</th>
                <th class="text-center">Used</th>
                <th class="text-center">Average Days to Sold</th>
                <th class="text-center">Total Gross</th>
                <th class="text-center">Average Gross</th>
                <th class="text-center">Average Gross New</th>
                <th class="text-center">Average Gross Used</th>
              </tr>
            </thead>
            
            <tbody>
              <tr v-for="data in soldRecapReportYearly" :key="data.key">
                <td class="text-center">{{ data.month }}</td>
                <td class="text-center">{{ data.sold }}</td>
                <td class="text-center">{{ data.new }}</td>
                <td class="text-center">{{ data.used }}</td>
                <td class="text-center">{{ data.average_days_to_sold }}</td>
                <td class="text-center">{{ data.total_gross }}</td>
                <td class="text-center">{{ data.average_gross }}</td>
                <td class="text-center">{{ data.average_gross_new }}</td>
                <td class="text-center">{{ data.average_gross_used }}</td>
              </tr>
            </tbody>
          </table>
          <img v-if="soldRecapReportYearlyLoader" style="display: block;margin-left: auto;margin-right: auto;width: 100px;" src="../../assets/img/Spinner.gif" />
        </div>
      </div>
    </div>
  </div>
  <!-- graph-area -->
</template>
<script>
import axios from "axios";
// import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import axios from "axios";
import moment from "moment";
// import jsPDF from "jspdf";
// import * as htmlToImage from "html-to-image";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// // import moment from "moment";
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";
export default {
  name: "AppointmentByRap",
  components: { DateRangePicker },
  data() {
    return {
      btnLoader: false,
      soldRecapReport: null,
      soldRecapReportLoader: false,
      soldRecapReportObject: null,
      soldRecapReportYearly: null,
      soldRecapReportYearlyLoader: false,
      soldRecapReportYearlyObject: null,
      filters_: {
        duration: "month",
        customDate: {},
        id: 0,
        tm_id: 0,
        from: "",
        to: "",
        export: false,
      },
    };
  },
  mounted() {
    console.log("component mounted");
    this.getReports();
    this.getSoldRecapReportYearly();
  },
  beforeUpdate() {
    if (this.soldRecapReportObject) {
      this.soldRecapReportObject.fnDestroy();
    }
    if (this.soldRecapReportYearlyObject) {
      this.soldRecapReportYearlyObject.fnDestroy();
    }
  },
  methods: {
    exportSalesRapData(duration) {
      this.filters_.export = true;
      this.btnLoader = true;
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(axios.defaults.baseURL + "soldrecapreport", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.filters_.export = false;
          this.btnLoader = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "soldrecapreport.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    getReports(duration = "month") {
      this.getSoldRecapReport(duration);
    },
    getSoldRecapReport(duration) {
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      $("#soldRecapReport").hide();
      this.soldRecapReportLoader = true;
      axios
        .post(axios.defaults.baseURL + "soldrecapreport", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((response) => {
          $("#soldRecapReport").show();
          if ($.fn.DataTable.isDataTable("#soldRecapReport")) {
            // $("#soldRecapReport").DataTable().destroy();
          }
          this.soldRecapReport = response.data;
          this.soldRecapReportLoader = false;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#soldRecapReport")) {
              $("#soldRecapReport").DataTable().draw();
            } else {
              $("#soldRecapReport").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
          // setTimeout(() => {
          //   this.soldRecapReportObject = $("#soldRecapReport").dataTable({
          //     paging: false,
          //     bFilter: false,
          //     ordering: false,
          //     bInfo: false,
          //   });
          // }, 500);
        }).catch(() => {
          this.soldRecapReportLoader = false;
        })
    },
    getSoldRecapReportYearly() {
      this.soldRecapReportYearlyLoader = true;
      axios
        .post(
          "soldrecapreport/currentyear",
          {},
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          this.soldRecapReportYearlyLoader = false;
          if ($.fn.DataTable.isDataTable("#soldRecapReportYearly")) {
            $("#soldRecapReportYearly").DataTable().destroy();
          }
          this.soldRecapReportYearly = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#soldRecapReportYearly")) {
              $("#soldRecapReportYearly").DataTable().draw();
            } else {
              $("#soldRecapReportYearly").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }

          },25);
          // setTimeout(() => {
          //   this.soldRecapReportYearlyObject = $(
          //     "#soldRecapReportYearly"
          //   ).dataTable({
          //     paging: false,
          //     bFilter: false,
          //     ordering: false,
          //     bInfo: false,
          //   });
          // }, 500);
        }).catch(() => {
          this.soldRecapReportYearlyLoader = false;
        })
    },
  },
};
</script>
<style scoped>
.timeline {
  margin: 0px !important;
}
.datepicker-timeline {
  overflow: visible;
  margin-right: 5px !important;
}
.calender {
  position: relative;
}
.calender .vue-daterange-picker {
  position: absolute;
}
.calender .daterangepicker.opensleft {
  right: -100px;
}
.td {
  position: relative;
  right: -7px;
}
@import "../../assets/css/dataTables.css";
</style>
