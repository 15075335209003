<template>
  <div id="canvas">
    <div class="download-search">
      <div class="row">
        <div class="col-md-4">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Internet Sales Report
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-8">
          <div class="download-links">
            <ul class="d-flex align-items-center justify-content-end">
              <!---start--->
              <li class="timeline">
                <button
                  type="button"
                  v-on:click="reportfilter('today')"
                  v-bind:class="filters_.duration == 'today' ? 'current' : ''"
                >
                  <span class="today">Today</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="filters_.duration == 'month' ? 'current' : ''"
                  v-on:click="reportfilter('month')"
                >
                  <span class="today">This Month</span>
                </button>
              </li>
              <li class="timeline datepicker-timeline">
                <button
                  type="button"
                  class="calender"
                  v-bind:class="filters_.duration == 'custom' ? 'current' : ''"
                >
                  <date-range-picker
                    :opens="'left'"
                    :ranges="false"
                    :locale-data="{ format: 'yyyy-mm-dd' }"
                    v-model="filters_.customDate"
                    @update="reportfilter('custom')"
                  ></date-range-picker
                  ><span class="calender">Custom Date</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  @click="exportSalesRapData(filters_.duration)"
                  :disabled="btnLoader"
                  type="button"
                >
                  <span v-if="btnLoader"
                    ><span class="spinner-grow spinner-grow-sm"></span>
                    Loading...</span
                  >
                  <span v-if="!btnLoader"> Export CSV</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- ounter-grid -->
    <div class="">
      <div
        class="dataTables_wrapper no-footer"
        v-if="
          this.$storage.get('auth').user.email == 'manager@genevamotors.com'
        "
      >
        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Lead Distribution</h4>
            </div>
          </div>
          <table id="userActivityData" class="customReportsTable display">
            <thead>
              <tr>
                <th class="text-center">Salesperson</th>
                <th class="text-center">Total Leads In</th>
                <th class="text-center">Bad Leads</th>

                <th class="text-center">Total Reassigned Leads</th>
                <th class="text-center">Active Leads</th>
                <th class="text-center">Inactive Leads / Lost Leads</th>

                <th class="text-center">Sold Leads</th>
                <th class="text-center">Closing Percentage</th>

                <th class="text-center">Average Response Time</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in useractivity_performance" :key="data.key">
                <td class="text-center">{{ data.name }}</td>
                <td class="text-center">{{ data.total_leads }}</td>
                <td class="text-center">{{ data.badleads }}</td>
                <td class="text-center">{{ data.reassign }}</td>
                <td class="text-center">{{ data.active_leads }}</td>
                <td class="text-center">{{ data.inactive }}</td>
                <td class="text-center">{{ data.sold }}</td>
                <td class="text-center">
                  {{ data.closing_percentage | roundoff }}
                </td>
                <td class="text-center">{{ data.avg_time }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Appointment Activity</h4>
            </div>
          </div>
          <table id="apptactivity" class="customReportsTable display">
            <thead>
              <tr>
                <th class="text-center">Salesperson</th>
                <th class="text-center">Appt Due</th>
                <th class="text-center">Appt Shown</th>
                <th class="text-center">Show Ratio</th>
                <th class="text-center">Appts Sold</th>
                <th class="text-center">Phone Calls</th>
                <th class="text-center">Email Sent</th>
                <th class="text-center">Front Gross</th>
                <th class="text-center">Back Gross</th>
                <th class="text-center">Total Gross</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in appointmentactivity" :key="data.key">
                <td class="text-center">{{ data.name }}</td>
                <td class="text-center">{{ data.appointment_created }}</td>
                <td class="text-center">{{ data.ApptCreatedShown }}</td>
                <td class="text-center">{{ data.showratio | roundoff }}</td>
                <td class="text-center">{{ data.appointment_sold }}</td>
                <td class="text-center">{{ data.emails }}</td>
                <td class="text-center">{{ data.calls }}</td>
                <td class="text-center">{{ data.front }}</td>
                <td class="text-center">{{ data.back }}</td>
                <td class="text-center">{{ data.total }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="graph-area">
      <div class="grap-title">
        <div class="title-grap">
          <h4>Lead Source Effectiveness</h4>
        </div>
      </div>
      <div class="leads-table-area report-table-area no-shadow">
        <table class="display" id="leads_sources_resports_table">
          <thead>
            <tr>
              <th class="text-center">Lead Source</th>
              <th class="text-center">Leads In</th>
              <th class="text-center">Be Back</th>
              <th class="text-center">Total Leads</th>
              <th class="text-center">Bad Leads</th>
              <th class="text-center">Monthly Budget</th>
              <th class="text-center">Call / Lead counts</th>
              <th class="text-center">Cost / Lead</th>
              <th class="text-center">Cars Sold</th>
              <th class="text-center">Cost / Car Sold</th>
              <th class="text-center">Closing/Ratio</th>
              <th class="text-center">Front Gross</th>
              <th class="text-center">Back Gross</th>
              <th class="text-center">Avg Gross</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(src, index) in orderedUsers" :key="index">
              <td class="text-center">{{ src.ls_name }}</td>
              <td class="text-center">{{ src.total_leads - src.badleads }}</td>
              <td class="text-center">{{ Math.ceil(Math.random() * 10) }}</td>
              <td class="text-center">{{ src.total_leads }}</td>
              <td class="text-center">{{ src.badleads }}</td>
              <td class="text-center">${{ src.b_budget }}</td>
              <td class="text-center">{{ src.total_leads }}</td>
              <td class="text-center">
                <span v-if="src.total_leads > 0">{{
                  (src.b_budget / src.total_leads) | roundoff
                }}</span
                ><span v-if="src.total_leads == 0">0</span>
              </td>

              <td class="text-center">{{ src.total_solds }}</td>
              <td class="text-center">
                <span v-if="src.total_solds > 0">{{
                  (src.b_budget / src.total_solds) | roundoff
                }}</span
                ><span v-if="src.total_solds == 0">0</span>
              </td>
              <td class="text-center">{{ src.close }}%</td>
              <td class="text-center">$ {{ src.frontgross }}</td>
              <td class="text-center">$ {{ src.backgross }}</td>
              <td class="text-center">$ {{ src.avg_gross }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- graph-area -->
</template>
<script>
import axios from "axios";
// import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import axios from "axios";
// import moment from "moment";
// import jsPDF from "jspdf";
// import * as htmlToImage from "html-to-image";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import moment from "moment";
import { orderBy } from "lodash";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
export default {
  name: "UserActivityReport",
  components: { DateRangePicker },
  data() {
    return {
      source: [],
      btnLoader: false,
      userActivityData: null,
      useractivity_performance: null,
      userActivityDataObject: null,
      sourceSummaryReport: null,
      appointmentactivity: null,
      filters_: {
        duration: "month",
        customDate: {},
        id: 0,
        tm_id: 0,
        from: "",
        to: "",
        export: false,
      },
    };
  },
  mounted() {
    this.getInternetSales();
    this.getAppointmentActivity();
    this.leadSourceReport();
  },
  computed: {
    orderbyactivity: function () {
      return orderBy(this.userActivityData, "total_tasks", "desc");
    },
    orderedUsers: function () {
      return orderBy(this.source, ["total_leads"], ["desc"]);
    },
  },
  beforeUpdate() {
    if (this.userActivityDataObject) {
      this.userActivityDataObject.fnDestroy();
    }
  },
  methods: {
    leadSourceReport(duration = "month", location = "") {
      console.log(location);
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post("leads_source_report", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res)
          if (res.data.success) {
            if ($.fn.DataTable.isDataTable("#leads_sources_resports_table")) {
              $("#leads_sources_resports_table").DataTable().destroy();
            }
            this.source = res.data.success;
            setTimeout(() => {
              if ($.fn.DataTable.isDataTable("#leads_sources_resports_table")) {
                $("#leads_sources_resports_table").DataTable().draw();
              } else {
                $("#leads_sources_resports_table").DataTable({
                  responsive: true,
                  paging: false,
                  bFilter: false,
                  ordering: false,
                  bInfo: false,
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exportSalesRapData(duration) {
      console.log(duration);
      this.filters_.export = true;
      this.btnLoader = true;
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(axios.defaults.baseURL + "useractivityreport", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.filters_.export = false;
          this.btnLoader = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "useractivity.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    reportfilter(duration = "month") {
      this.getInternetSales(duration);
      this.getAppointmentActivity(duration);
      this.leadSourceReport(duration, "create");
      // this.useractivityPerformance(duration);
    },
    getInternetSales(duration = "month") {
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(axios.defaults.baseURL + "getinternetsales", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#userActivityData")) {
            $("#userActivityData").DataTable().destroy();
          }
          this.useractivity_performance = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#userActivityData")) {
              $("#userActivityData").DataTable().draw();
            } else {
              $("#userActivityData").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getAppointmentActivity(duration = "month") {
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(
          axios.defaults.baseURL + "getAppointmentActivity",
          this.filters_,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#apptactivity")) {
            $("#apptactivity").DataTable().destroy();
          }
          this.appointmentactivity = response.data;
          console.log(response.data);
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#apptactivity")) {
              $("#apptactivity").DataTable().draw();
            } else {
              $("#apptactivity").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getSourceSummaryReprot(duration = "month") {
      let startDate = this.filters_.customDate.startDate;
      let endDate = this.filters_.customDate.endDate;
      this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.filters_.duration = duration;
      axios
        .post(axios.defaults.baseURL + "sourcesummaryreport", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#sourceSummaryReport")) {
            $("#sourceSummaryReport").DataTable().destroy();
          }
          this.sourceSummaryReport = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#sourceSummaryReport")) {
              $("#sourceSummaryReport").DataTable().draw();
            } else {
              $("#sourceSummaryReport").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
  },
};
</script>
<style scoped>
.timeline {
  margin: 0px !important;
}
.datepicker-timeline {
  overflow: visible;
  margin-right: 5px !important;
}
.calender {
  position: relative;
}
.calender .vue-daterange-picker {
  position: absolute;
}
.calender .daterangepicker.opensleft {
  right: -100px;
}
.td {
  position: relative;
  right: -7px;
}
@import "../../assets/css/dataTables.css";
</style>
