<template>
  <DashboardContainer>
    <leads-reports v-if="this.$route.params.cate == 'leads'" />
    <agents-reports v-if="this.$route.params.cate == 'agents'" />
    <users-reports v-if="this.$route.params.cate == 'users'" />
    <inventory-report v-if="this.$route.params.cate == 'inventory'" />
    <Performance v-if="this.$route.params.cate == 'performanace'" />
    <ManagersTopDownReport
      v-if="this.$route.params.cate == 'managers-top-down'"
    />
    <BackIntoReport v-if="this.$route.params.cate == 'back-into-report'" />
    <AppointementByRap
      v-if="this.$route.params.cate == 'appointement-by-rap'"
    />
    <SalesRepReport v-if="this.$route.params.cate == 'sales-rep-report'" />
    <UserActivityReport
      v-if="this.$route.params.cate == 'user-activity-report'"
    />
    <LostReport v-if="this.$route.params.cate == 'lost-report'" />
    <DealerShipPerformance
      v-if="this.$route.params.cate == 'dealership-performance-report'"
    />
    <SourceSummaryReport
      v-if="this.$route.params.cate == 'source-summary-report'"
    />
    <BDCReport v-if="this.$route.params.cate == 'bdc-report'" />
    <ReponseTimeReport
      v-if="this.$route.params.cate == 'response-time-report'"
    />
    <SoldRecapReport v-if="this.$route.params.cate == 'sold-recap-report'" />
    <VehicleTrendsReport
      v-if="this.$route.params.cate == 'vehicle-trends-report'"
    />
    <InternetSalesReport v-if="this.$route.params.cate == 'internet-sales-report'"></InternetSalesReport>
    <SystemReport v-if="this.$route.params.cate == 'system-report'" />
    <SoldDealRecap v-if="this.$route.params.cate == 'desking-recap-report'" />
    <!-- <ActivityReports v-if="this.$route.params.cate == 'activity'"/>
        <SalesReports v-if="this.$route.params.cate == 'sales'"/>-->
    <transition name="fade">
      <div id="pagetop" class="fixed-right-bottom-to-top" v-show="scY > 300" @click="toTop">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
              stroke="#fff"
              stroke-width="1.5" stroke-linecap="square" stroke-linejoin="arcs">
          <path d="M18 15l-6-6-6 6"/>
        </svg>
      </div>
    </transition>
  </DashboardContainer>
</template>
<script>
import DashboardContainer from "../containers/DashboardContainer";
import LeadsReports from "../components/reports/LeadsReports";
import AgentsReports from "../components/reports/AgentReports";
import UsersReports from "../components/reports/UsersReports";
import InventoryReport from "../components/reports/InventoryReport";
import Performance from "../components/reports/Performance";
import ManagersTopDownReport from "../components/reports/ManagersTopDownReport";
import BackIntoReport from "../components/reports/BackIntoReport";
import AppointementByRap from "../components/reports/AppointementByRap";
import SalesRepReport from "../components/reports/SalesRepReport";
import UserActivityReport from "../components/reports/UserActivityReport";
import LostReport from "../components/reports/LostReport";
import DealerShipPerformance from "../components/reports/DealerShipPerformance";
import SourceSummaryReport from "../components/reports/SourceSummaryReport";
import BDCReport from "../components/reports/BDCReport";
import ReponseTimeReport from "../components/reports/ReponseTimeReport";
import SoldRecapReport from "../components/reports/SoldRecapReport";
import VehicleTrendsReport from "../components/reports/VehicleTrendsReport";
import SystemReport from "../components/reports/SystemReport";
import SoldDealRecap from "../components/reports/SoldDealRecap";
import InternetSalesReport from "../components/reports/InternetSalesReport";
// import ActivityReports from '../components/reports/ActivityReports'
// import SalesReports from '../components/reports/SalesReports'
export default {
  name: "Reports",
  data : () => {
    return {
      scTimer: 0,
      scY: 0,
    }
  }, 
  beforeCreate: function () {
    var category = this.$route.params.cate;
    var title = this.$route.meta.pageTitle;
    switch (category) {
      case 'leads':
        title = 'Lead Report';
        break;
      case 'agents':
        title = 'Agent Performance Report';
      break;
      case 'users':
        title = 'Competitive Report';
      break;
      case 'inventory':
        title = 'Inventory Report';
        break;
      case 'performanace':
      title = 'Performance Report';
      break;
      case 'managers-top-down':
      title = 'Managers Top Down Report';
      break;
      case 'back-into-report':
      title = 'Back into Report';
      break;
      case 'appointement-by-rap':
      title = 'Appointment By Rep';
      break;
      case 'sales-rep-report':
      title = 'Sales Rep Report';
      break;
      case 'user-activity-report':
      title = 'User Activity Report';
      break;
      case 'lost-report':
      title = 'Lost Report';
      break;
      case 'dealership-performance-report':
      title = 'Dealership Performance';
      break;
      case 'source-summary-report':
      title = 'Source Summary Report';
      break;
      case 'bdc-report':
      title = 'BDC Report';
      break;
      case 'response-time-report':
      title = 'Response Time Report';
      break;
      case 'sold-recap-report':
      title = 'Sold Recap Report';
      break;
      case 'vehicle-trends-report':
      title = 'Vehicle Trends Report';
      break;
      case 'internet-sales-report':
      title = 'Internet Sales Report';
      break;
      case 'system-report':
      title = 'System Report';
      break;
      case 'desking-recap-report':
      title = 'Desking Recap Report';
      break;
    
      default:
        title = 'Reports'
        break;
    }
    this.$route.meta.pageTitle = title;

    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  components: {
    DashboardContainer,
    LeadsReports,
    AgentsReports,
    UsersReports,
    InventoryReport,
    Performance,
    ManagersTopDownReport,
    BackIntoReport,
    AppointementByRap,
    SalesRepReport,
    UserActivityReport,
    LostReport,
    DealerShipPerformance,
    SourceSummaryReport,
    BDCReport,
    ReponseTimeReport,
    SoldRecapReport,
    VehicleTrendsReport,
    SystemReport,
    SoldDealRecap,
    InternetSalesReport
    //ActivityReports,
    //SalesReports,
  },
  beforeUpdate: function () {
    var category = this.$route.params.cate;
    var title = this.$route.meta.pageTitle;
    switch (category) {
      case 'leads':
        title = 'Lead Report';
        break;
      case 'agents':
        title = 'Agent Performance Report';
      break;
      case 'users':
        title = 'Competitive Report';
      break;
      case 'inventory':
        title = 'Inventory Report';
        break;
      case 'performanace':
      title = 'Performance Report';
      break;
      case 'managers-top-down':
      title = 'Managers Top Down Report';
      break;
      case 'back-into-report':
      title = 'Back into Report';
      break;
      case 'appointement-by-rap':
      title = 'Appointment By Rep';
      break;
      case 'sales-rep-report':
      title = 'Sales Rep Report';
      break;
      case 'user-activity-report':
      title = 'User Activity Report';
      break;
      case 'lost-report':
      title = 'Lost Report';
      break;
      case 'dealership-performance-report':
      title = 'Dealership Performance';
      break;
      case 'source-summary-report':
      title = 'Source Summary Report';
      break;
      case 'bdc-report':
      title = 'BDC Report';
      break;
      case 'response-time-report':
      title = 'Response Time Report';
      break;
      case 'sold-recap-report':
      title = 'Sold Recap Report';
      break;
      case 'vehicle-trends-report':
      title = 'Vehicle Trends Report';
      break;
      case 'internet-sales-report':
      title = 'Internet Sales Report';
      break;
      case 'system-report':
      title = 'System Report';
      break;
      case 'desking-recap-report':
      title = 'Desking Recap Report';
      break;
    
      default:
        title = 'Reports'
        break;
    }
    this.$route.meta.pageTitle = title;
  },
  updated: function () {
    var category = this.$route.params.cate;
    var title = this.$route.meta.pageTitle;
    switch (category) {
      case 'leads':
        title = 'Lead Report';
        break;
      case 'agents':
        title = 'Agent Performance Report';
      break;
      case 'users':
        title = 'Competitive Report';
      break;
      case 'inventory':
        title = 'Inventory Report';
        break;
      case 'performanace':
      title = 'Performance Report';
      break;
      case 'managers-top-down':
      title = 'Managers Top Down Report';
      break;
      case 'back-into-report':
      title = 'Back into Report';
      break;
      case 'appointement-by-rap':
      title = 'Appointment By Rep';
      break;
      case 'sales-rep-report':
      title = 'Sales Rep Report';
      break;
      case 'user-activity-report':
      title = 'User Activity Report';
      break;
      case 'lost-report':
      title = 'Lost Report';
      break;
      case 'dealership-performance-report':
      title = 'Dealership Performance';
      break;
      case 'source-summary-report':
      title = 'Source Summary Report';
      break;
      case 'bdc-report':
      title = 'BDC Report';
      break;
      case 'response-time-report':
      title = 'Response Time Report';
      break;
      case 'sold-recap-report':
      title = 'Sold Recap Report';
      break;
      case 'vehicle-trends-report':
      title = 'Vehicle Trends Report';
      break;
      case 'internet-sales-report':
      title = 'Internet Sales Report';
      break;
      case 'system-report':
      title = 'System Report';
      break;
      case 'desking-recap-report':
      title = 'Desking Recap Report';
      break;
    
      default:
        title = 'Reports'
        break;
    }
    this.$route.meta.pageTitle = title;
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
};
</script>