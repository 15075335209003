<template>
  <div id="canvas">
    <div class="download-search">
      <div class="row">
        <div class="col-md-4">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Inventory Report
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-8">
          <div class="download-links">
            <ul>
              <li>
                <a href="#" v-on:click="downloadIt('print')"
                  ><img
                    class="img-fluid print-icon"
                    src="../../assets/img/print.svg"
                    alt=""
                /></a>
              </li>
              <li>
                <a href="#" v-on:click="downloadIt('pdf')"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/pdfp.svg"
                    alt=""
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- ounter-grid -->
    <div class="counter-grid">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-4">
          <div class="counters">
            <div class="thamnils">
              <img
                class="img-fluid"
                src="../../assets/img/carscount.svg"
                alt=""
              />
            </div>
            <div class="counter-num">
              <p>Total</p>
              <h4>{{ totalinventory }}</h4>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4">
          <div class="counters">
            <div class="thamnils">
              <img
                class="img-fluid"
                src="../../assets/img/Vehicles.svg"
                alt=""
              />
            </div>
            <div class="counter-num">
              <p>Used</p>
              <h4>{{ usedInventory }}</h4>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4">
          <div class="counters">
            <div class="thamnils">
              <img
                class="img-fluid"
                src="../../assets/img/giftcount.svg"
                alt=""
              />
            </div>
            <div class="counter-num">
              <p>New</p>
              <h4>{{ newInventory }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="graph-area">
      <div class="grap-title">
        <div class="title-grap">
          <h4>Sold by condition</h4>
        </div>
        <div class="Sold-Leads">
          <ul>
            <li><span class="Used-dot"></span>Used</li>
            <li><span class="New-dot"></span>New</li>
          </ul>
        </div>
        <div class="timeline">
          <button
            v-bind:class="filters_.duration == 'today' ? 'current' : ''"
            v-on:click="updateGraphs('today')"
            type="button"
            id="today"
          >
            <span class="today">Today</span>
          </button>
          <button
            v-bind:class="filters_.duration == 'month' ? 'current' : ''"
            v-on:click="updateGraphs('month')"
            type="button"
          >
            <span class="today">This Month</span>
          </button>
          <button
            type="button"
            v-bind:class="filters_.duration == 'custom' ? 'current' : ''"
          >
            <date-range-picker
              :opens="'left'"
              :ranges="false"
              :locale-data="{ format: 'yyyy-mm-dd' }"
              v-model="filters_.customDate"
              @update="updateGraphs('custom')"
            ></date-range-picker>
            <span class="month">Custom Date</span>
          </button>
        </div>
      </div>
      <div class="inventory-graph">
        <apexchart
          type="bar"
          :options="chartOptions"
          :series="series"
          height="200"
        ></apexchart>
      </div>

      <div class="inventoryreport-tabs">
        <!-- report-tabs -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              v-on:click="updateGraphs(filters_.duration)"
              class="nav-link active"
              id="New-tab"
              data-toggle="tab"
              href="#New"
              role="tab"
              aria-controls="New"
              aria-selected="false"
              >New Inventory</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              v-on:click="updateGraphs(filters_.duration)"
              class="nav-link"
              id="Pre-tab"
              data-toggle="tab"
              href="#Pre"
              role="tab"
              aria-controls="Pre"
              aria-selected="true"
              >Used Inventory</a
            >
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade"
            id="Pre"
            role="tabpanel"
            aria-labelledby="Pre-tab"
          >
            <!-- Car Sold by Body Type -->
            <div class="title-grap carsoldbody">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Car Sold by Body Type</h4>
                </div>
                <!-- <div class="col-sm-6">
                                  <div class="download-links">
                                    <ul>
                                      <li>Download</li>
                                      <li>
                                        <a href="#"
                                          ><img class="img-fluid" src="assets/img/pdf.svg" alt=""
                                        /></a>
                                      </li>
                                      <li>
                                        <a href="#"
                                          ><img class="img-fluid" src="assets/img/pdfp.svg" alt=""
                                        /></a>
                                      </li>
                                    </ul>
                                  </div>
                                </div> -->
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4">
                <template v-if="soldbycarlabels.length > 0">
                  <div class="analytics-graph">
                    <div class="graph">
                      <apexchart
                        type="donut"
                        height="350"
                        v-if="newinventory"
                        :options="getChartOptions(soldbycarlabels)"
                        :series="carsoldbytypedata"
                      ></apexchart>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div style="width: 290px;height: 110px;display: flex; align-items: center;">
                    <p style="margin: auto;" class="text-center">No vehicles found.</p>
                  </div>
                </template>
              </div>
              <div class="col-xl-8">
                <div class="analyticss inventory-dot">
                  <div class="all-analytic">
                    <div class="analytics-box funnel-list">
                      <div
                        class="media"
                        v-for="info in soldbycarinfo"
                        :key="info.title"
                      >
                        <p class="mr-3"><span :class="info.class"></span></p>
                        <div class="media-body">
                          <h5 class="mt-0">{{ info.title }}</h5>
                          <h3>{{ info.count }}</h3>
                        </div>
                      </div>
                      <!-- <div class="media">
                                              <p class="mr-3"><span class="color2"></span></p>
                                              <div class="media-body">
                                                <h5 class="mt-0">Crew Cab Pickup</h5>
                                                <h3></h3>
                                              </div>
                                            </div>
                                            <div class="media">
                                              <p class="mr-3"><span class="color3"></span></p>
                                              <div class="media-body">
                                                <h5 class="mt-0">Car</h5>
                                                <h3></h3>
                                              </div>
                                            </div> -->
                    </div>
                    <!-- <div class="analytics-box">
                                          <div class="media">
                                            <p class="mr-3"><span class="color4"></span></p>
                                            <div class="media-body">
                                              <h5 class="mt-0">Coupe</h5>
                                              <h3>300</h3>
                                            </div>
                                          </div>
                                          <div class="media">
                                            <p class="mr-3"><span class="color5"></span></p>
                                            <div class="media-body">
                                              <h5 class="mt-0">Convertible</h5>
                                              <h3>123</h3>
                                            </div>
                                          </div>
                                          <div class="media">
                                            <p class="mr-3"><span class="color6"></span></p>
                                            <div class="media-body">
                                              <h5 class="mt-0">Wagon</h5>
                                              <h3>355</h3>
                                            </div>
                                          </div>
                                        </div> -->
                    <!-- <div class="analytics-box">
                                          <div class="media">
                                            <p class="mr-3"><span class="color7"></span></p>
                                            <div class="media-body">
                                              <h5 class="mt-0">Van</h5>
                                              <h3>23</h3>
                                            </div>
                                          </div>
                                          <div class="media">
                                            <p class="mr-3"><span class="color8"></span></p>
                                            <div class="media-body">
                                              <h5 class="mt-0">Jeep</h5>
                                              <h3>230</h3>
                                            </div>
                                          </div>
                                        </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- Car Sold by Body Type -->

            <!-- Car Sold by Body Type -->
            <div class="title-grap carsoldbody">
              <h4>cars sold by Model</h4>
            </div>

            <div class="row">
              <div class="col-xl-4">
                <template v-if="soldbymodallabels.length > 0">
                  <div class="analytics-graph">
                    <div class="graph">
                      <apexchart
                        type="donut"
                        height="350"
                        :options="getChartOptions(soldbymodallabels)"
                        :series="carsoldbytypemodel"
                        v-if="carsoldbytypemodeldonut"
                      ></apexchart>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div style="width: 290px;height: 110px;display: flex; align-items: center;">
                    <p style="margin: auto;" class="text-center">No vehicles found.</p>
                  </div>
                </template>
              </div>
              <div class="col-xl-8">
                <div class="analyticss inventory-dot">
                  <div class="all-analytic">
                    <div class="analytics-box funnel-list">
                      <div class="col-md-12">
                        <div
                          class="media"
                          v-for="info in carsoldbytypemodelinfo"
                          :key="info.title"
                        >
                          <p class="mr-3"><span :class="info.class"></span></p>
                          <div class="media-body">
                            <h5 class="mt-0">{{ info.title }}</h5>
                            <h3>{{ info.count }}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Car Sold by Body Type -->
          </div>
          <div
            class="tab-pane fade show active"
            id="New"
            role="tabpanel"
            aria-labelledby="New-tab"
          >
            <!-- Car Sold by Body Type -->
            <div class="title-grap carsoldbody">
              <div class="row">
                <div class="col-sm-6">
                  <h4>Car Sold by Body Type</h4>
                </div>
                <!-- <div class="col-sm-6">
                                  <div class="download-links">
                                    <ul>
                                      <li>Download</li>
                                      <li>
                                        <a href="#"
                                          ><img
                                            class="img-fluid"
                                            src="../../assets/img/pdf.svg"
                                            alt=""
                                        /></a>
                                      </li>
                                      <li>
                                        <a href="#"
                                          ><img
                                            class="img-fluid"
                                            src="assets/img/pdfp.svg"
                                            alt=""
                                        /></a>
                                      </li>
                                    </ul>
                                  </div>
                                </div> -->
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4">
                <template v-if="newcarsoldbytypelabel.length > 0">
                  <div class="analytics-graph">
                    <div class="graph">
                      <apexchart
                        v-if="newcarsoldbytypedatadonut"
                        type="donut"
                        height="350"
                        :options="getChartOptions(newcarsoldbytypelabel)"
                        :series="newcarsoldbytypedata"
                      ></apexchart>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div style="width: 290px;height: 110px;display: flex; align-items: center;">
                    <p style="margin: auto;" class="text-center">No vehicles found.</p>
                  </div>
                </template>
                
              </div>
              <div class="col-xl-8">
                <div class="analyticss inventory-dot">
                  <div class="all-analytic">
                    <div class="analytics-box funnel-list">
                      <div
                        class="media"
                        v-for="info in newcarsoldbytypedatainfo"
                        :key="info.title"
                      >
                        <p class="mr-3"><span :class="info.class"></span></p>
                        <div class="media-body">
                          <h5 class="mt-0">{{ info.title }}</h5>
                          <h3>{{ info.count }}</h3>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="analytics-box">
                                          <div class="media">
                                            <p class="mr-3"><span class="color4"></span></p>
                                            <div class="media-body">
                                              <h5 class="mt-0">Coupe</h5>
                                              <h3>300</h3>
                                            </div>
                                          </div>
                                          <div class="media">
                                            <p class="mr-3"><span class="color5"></span></p>
                                            <div class="media-body">
                                              <h5 class="mt-0">Convertible</h5>
                                              <h3>123</h3>
                                            </div>
                                          </div>
                                          <div class="media">
                                            <p class="mr-3"><span class="color6"></span></p>
                                            <div class="media-body">
                                              <h5 class="mt-0">Wagon</h5>
                                              <h3>355</h3>
                                            </div>
                                          </div>
                                        </div> -->
                    <!-- <div class="analytics-box">
                                          <div class="media">
                                            <p class="mr-3"><span class="color7"></span></p>
                                            <div class="media-body">
                                              <h5 class="mt-0">Van</h5>
                                              <h3>23</h3>
                                            </div>
                                          </div>
                                          <div class="media">
                                            <p class="mr-3"><span class="color8"></span></p>
                                            <div class="media-body">
                                              <h5 class="mt-0">Jeep</h5>
                                              <h3>230</h3>
                                            </div>
                                          </div>
                                        </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- Car Sold by Body Type -->

            <!-- Car Sold by Body Type -->
            <div class="title-grap carsoldbody">
              <h4>cars sold by Model</h4>
            </div>

            <div class="row">
              <div class="col-xl-4">
                <template v-if="newcarsoldbymodellabel.length > 0">
                  <div class="analytics-graph funnel-list">
                    <div class="graph">
                      <apexchart
                        type="donut"
                        height="350"
                        :options="getChartOptions(newcarsoldbymodellabel)"
                        :series="newcarsoldbymodeldata"
                        v-if="newcarsoldbymodeldatadonot"
                      ></apexchart>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div style="width: 290px;height: 110px;display: flex; align-items: center;">
                    <p style="margin: auto;" class="text-center">No vehicles found.</p>
                  </div>
                </template>
                
              </div>
              <div class="col-xl-8">
                <div class="analyticss inventory-dot">
                  <div class="all-analytic">
                    <div class="analytics-box funnel-list">
                      <div
                        class="media"
                        v-for="info in newcarsoldbymodelinfo"
                        :key="info.title"
                      >
                        <p class="mr-3"><span :class="info.class"></span></p>
                        <div class="media-body">
                          <h5 class="mt-0">{{ info.title }}</h5>
                          <h3>{{ info.count }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Car Sold by Body Type -->
          </div>
        </div>
      </div>
      <!-- report-tabs -->
    </div>
    <div id="print_area" class="print_area">
      <img :src="print_src" style="max-width: 100%" />
    </div>
  </div>
  <!-- graph-area -->
</template>
<script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import $ from "jquery";

export default {
  name: "inventoryreport",
  components: { DateRangePicker, apexchart: VueApexCharts },
  data() {
    return {
      totalinventory: 0,
      newInventory: 0,
      usedInventory: 0,
      print_src: "",
      output: null,
      newinventory: false,
      newcarsoldbymodeldatadonot: false,
      newcarsoldbytypedatadonut: false,
      carsoldbytypemodeldonut: false,
      filters_: {
        duration: "today",
        customDate: {},
        id: 0,
        tm_id: 0,
        from: "",
        to: "",
      },
      newcarsoldbymodelinfo: [],
      carsoldbytypemodelinfo: [],
      soldbycarinfo: [],
      newcarsoldbytypedatainfo: [],
      newcarsoldbytypedata: [],
      carsoldbytypecount: [],
      newcarsoldbytypecount: [],
      newcarsoldbymodeldata: [],
      newcarsoldbymodelcount: [],
      carsoldbytypemodel: [],
      carsoldbymodalcount: [],
      soldbymodallabel: [],
      soldbycarlabels: [],
      soldbymodallabels : [],
      carsoldbytypedata: [],
      usedcarbymodellabel: [],
      newcarsoldbytypelabel: [],
      newcarsoldbymodellabel: [],
      activityChartData: [],
      analyticsChartData: [],
      analyticsData: [],
      base_url: axios.defaults.baseURL,
      chart_titles: [],
      leadsChartData: [],
      stats: {},

      series: [],

      chartOptions: {
        chart: {
          type: "bar",
        },
        colors: ["#A2A8B8", "#3150A1"],
        plotOptions: {
          bar: {
            horizontal: true,
            startingShape: "rounded",
            endingShape: "rounded",
            barHeight: "20%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },

        xaxis: {
          categories: ["Used", "New"],
        },
      },
      DonutchartOptionsbytype: {
        chart: {
          type: "donut",
        },

        colors: ["#3151A1", "#660CF8", "#BA0CF8"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      DonutchartOptionsbymodel: {
        chart: {
          type: "donut",
        },
        legend: {
          show: false,
        },
        colors: [
          "#3151A1",
          "#BE1AF8",
          "#BA0CF8",
          "#F80C61",
          "#FFC83B",
          "#F3F3F3",
          "#FFFFFF",
          "#3151A1",
          "#BE1AF8",
          "#BA0CF8",
          "#F80C61",
          "#FFC83B",
          "#F3F3F3",
          "#FFFFFF",
          "#3151A1",
          "#BE1AF8",
          "#BA0CF8",
          "#F80C61",
          "#FFC83B",
          "#F3F3F3",
          "#FFFFFF",
        ],
        dataLabels: {
          enabled: false,
        },
      },
    };
  },
  computed:{
    IsV2(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_version){
          if(this.$storage.get('auth').user.dealer_autolink_version == 'v2'){
            return true;
          }
        }
      }
      return false;
    },
    IsV2TokenExist(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token){
          if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token != ''){
            return true;
          }
        }
      }
      return false;
    },
    V2AuthHeader(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token){
          if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token != ''){
            return {
              'Authorization' : 'Bearer ' + this.$storage.get('auth').user.dealer_autolink_v2_auth_token
            };
          }
        }
      }
      return {};
    }
  },
  created() {
    this.getStats();
    this.getSoldByConditionReport();
    this.carsoldbytype();
    this.carsoldbymodel();
    this.newcarsoldbymodel();
    this.newcarsoldbytype();
    this.getInventory();
  },
  methods: {
    getInventory() {
      if(this.IsV2 && this.IsV2TokenExist){
        axios.post("https://api.dealersgear.com/api/crmInventory?limit=1000&offset=0",{},{
          headers : this.V2AuthHeader
        }).then((res) => {
            if (res.data.status) {
              this.allInventory = res.data.inventory;
              console.log(res.data.inventory);
  
              res.data.inventory.forEach((data) => {
                this.totalinventory = Number(this.totalinventory) + 1;
                if (
                  data.new_used == "New" ||
                  data.new_used.toLowerCase == "new"
                ) {
                  this.newInventory = Number(this.newInventory) + 1;
                } else {
                  this.usedInventory = Number(this.usedInventory) + 1;
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }else{
        axios
          .get(
            "https://autolinkme.com/webservices/crm/inventory?id=" +
              this.$storage.get("auth").user.dealer_autolink_id +
              "&limit=1000&offset=0&status=active"
          )
          .then((res) => {
            if (res.data.status) {
              this.allInventory = res.data.data;
              console.log(res.data.data);
  
              res.data.data.forEach((data) => {
                this.totalinventory = Number(this.totalinventory) + 1;
                if (
                  data.new_used == "New" ||
                  data.new_used.toLowerCase == "new"
                ) {
                  this.newInventory = Number(this.newInventory) + 1;
                } else {
                  this.usedInventory = Number(this.usedInventory) + 1;
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getChartOptions(chartTitle) {
      var opt = {
        chart: {
          type: "donut",
        },
        legend: {
          show: false,
        },
        labels: chartTitle,
        colors: ["#793be3", "#47ceff", "#19be93", "#ffcb46", "#ff470e"],
        dataLabels: {
          enabled: false,
        },
      };
      return opt;
    },
    downloadIt(type = "") {
      if (type == "img") {
        htmlToImage
          .toJpeg(document.getElementById("canvas"))
          .then(function (dataUrl) {
            var link = document.createElement("a");
            link.download = Date.now() + ".jpeg";
            link.href = dataUrl;
            link.click();
          });
      } else if (type == "pdf") {
        const doc = new jsPDF("p", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        htmlToImage
          .toJpeg(document.getElementById("canvas"))
          .then(function (dataUrl) {
            doc.addImage(dataUrl, "JPEG", 0, 0, width, height);
            doc.save();
          });
      } else if (type == "print") {
        let vm = this;
        htmlToImage
          .toJpeg(document.getElementById("canvas"))
          .then(function (dataUrl) {
            //console.log(dataUrl)
            vm.print_src = dataUrl;
            setTimeout(() => {
              vm.$htmlToPaper("print_area");
            }, 500);
          });
      }
    },

    updateGraphs(val) {
      if (val == "today") {
        this.filters_.duration = "today";
        this.getSoldByConditionReport();
        this.carsoldbytype();
        this.carsoldbymodel();
        this.newcarsoldbymodel();
        this.newcarsoldbytype();
        $("#month").removeClass("current");
      } else if (val == "month") {
        this.filters_.duration = "month";
        $("#today").removeClass("current");
        this.getSoldByConditionReport();
        this.carsoldbytype();
        this.carsoldbymodel();
        this.newcarsoldbymodel();
        this.newcarsoldbytype();
      } else if (val == "custom") {
        this.filters_.duration = "custom";
        let startDate = this.filters_.customDate.startDate;
        let endDate = this.filters_.customDate.endDate;
        if (
          startDate != undefined &&
          startDate != "undefined" &&
          startDate != null &&
          startDate != ""
        ) {
          this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
        }
        if (
          endDate != undefined &&
          endDate != "undefined" &&
          endDate != null &&
          endDate != ""
        ) {
          this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
        }
        this.getSoldByConditionReport();
        this.carsoldbytype();
        this.carsoldbymodel();
        this.newcarsoldbymodel();
        this.newcarsoldbytype();
      }
    },
    getStats() {
      axios
        .get("get/inventory/stats", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.stats = res.data.stats;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSoldByConditionReport() {
      axios
        .post("getsoldByCondition", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.chartOptions.labels = res.data.success.labels;
          this.series = res.data.success.data;
          this.series = this.series.map((item) => {
            if(item.name){
              item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1);
            }
            return item;
          });
          console.log(res.data.success.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    carsoldbytype() {
      //console.log(this.agentActivity_)
      axios
        .post("carsoldbytype", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          // console.log(res.data.chart_data.data)
          this.carsoldbytypedata = res.data.count;
          this.soldbycarlabels = res.data.label;
          this.soldbycarinfo = res.data.data;
          this.newinventory = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newcarsoldbytype() {
      //console.log(this.agentActivity_)
      axios
        .post("newcarsoldbytype", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          // console.log(res.data.chart_data.data)
          this.newcarsoldbytypedata = res.data.count;
          this.newcarsoldbytypedatainfo = res.data.count;
          this.newcarsoldbytypedatadonut = true;
          this.newcarsoldbytypelabel = res.data.label;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    carsoldbymodel() {
      axios
        .post("carsoldbymodal", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          // console.log(res.data.chart_data.data)
          //  this.DonutchartOptionsbymodel = {
          //     labels: res.data.label,
          //   };
          this.carsoldbytypemodel = res.data.count;
          this.carsoldbytypemodelinfo = res.data.data;
          this.soldbymodallabels = res.data.label;
          this.carsoldbytypemodeldonut = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newcarsoldbymodel() {
      axios
        .post("newcarsoldbymodal", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          // console.log(res.data.chart_data.data)
          this.newcarsoldbymodeldata = res.data.count;
          this.newcarsoldbymodelinfo = res.data.data;
          this.newcarsoldbymodellabel = res.data.label;
          this.newcarsoldbymodeldatadonot = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.color1 {
  background: red;
}

.media {
  width: 31%;
  float: left;
  padding: 5px 0px;
  margin: 5px;
}

.media-body {
  flex: 1;
  display: inline-block;
}

.analytics-box .media p.mr-3 {
  font-size: 10px;
  display: inline-block;
  position: relative;
  bottom: -2px;
}

.media-body {
  flex: 1;
  display: inline-block;
}
.funnel-list {
  width: 100%;
}
</style>
